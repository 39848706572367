import React from 'react'
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// import fuji_step_2 from "src/assets/img/cameraInstallation/fuji/2.jpg";
import step1 from "../assets/img/panoheadUsage/step1.jpg";
import step2 from "../assets/img/panoheadUsage/step2.jpg";
import step3 from "../assets/img/panoheadUsage/step3.jpg";
import step2_2 from "../assets/img/panoheadUsage/step2_2.PNG";
import step2_3 from "../assets/img/panoheadUsage/step2_3.png";
import step3_1 from "../assets/img/panoheadUsage/step3_1.jpg";
import step4_1 from "../assets/img/panoheadUsage/step4_1.png";
import step5_1 from "../assets/img/panoheadUsage/step5_1.jpg";
import {Link} from "react-router-dom";

function getSteps() {
    return ['Przygotowanie głowicy', 'Łączenie z urządzeniem', 'Kalibracja początkowa', 'Ustawienia fotografowania', 'Sesja zdjęciowa'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <div>
                <p>1.1 Przykręć głowicę do statywu trzymając za zaznaczony element.</p>
                <img src={step1} alt='Fuji xt20' width={'50%'}/>
                <p>1.2 Podnieś pokrywę głowicy i włóż akumulatory, następnie zamknij pokrywę (</p>
                <img src={step2} alt='Fuji xt20' width={'50%'}/>

                <p>1.3 Zamontuj aparat na głowicy (sprawdź zakładkę <Link to='/camera-installation'><strong
                    style={{color: '#004AD1'}}>MONTAŻ APARATÓW</strong></Link>)</p>
                <p>1.4 Podłącz moduł bluetooth do gniazda AUX</p>
                <img src={step3} alt='Fuji xt20' width={'50%'}/>
            </div>
        case 1:
            return <div>
                <p>2.1 Włącz komputer i otwórz program <strong>Papywizard</strong></p>
                <img src={step2_2} alt='Fuji xt20' width={'50%'}/>

                <p>2.2 Włącz głowicę czerwonym przyciskiem od spodu (ustawiając przełącznik w pozycję 1 (kreska))</p>
                <p>2.3 Połącz się z głowicą klikając <strong>Sprzęt -> Połącz się</strong></p>
                <img src={step2_3} alt='Fuji xt20' width={'50%'}/>
                <p>2.4 Na dolnym pasku powinien pojawić się komunikat <strong>'Trwa nawiązywanie połączenia z prawdziwym
                    sprzętem...' </strong></p>
                <p>2.5 Następnie na dolnym pasku powinien pojawić się komunikat <strong>'Połączenie ze sprzętem
                    nawiązane' </strong></p>
                <br/>
                <p><strong>Możliwe problemy</strong></p>
                <p>1. Program zamyka się po kliknięciu przycisku 'Połącz się'</p><p> Moduł bluetooth w głowicy jest
                niedostępny (nie jest podłączony prawidłowo / głowica jest wyłączona lub rozładowana / głowica jest poza
                zasięgiem)</p>
                <p>2. Program zawiesza się</p>
                <p>Zamknij program i włącz go ponownie. Jeśli to nie pomaga to wyłącz i włącz komputer</p>
            </div>
        case 2:
            return <div>
                <p>3.1 Korzystając ze strzałek w prawej części programu (lub tych na klawiaturze komputera) ustaw położenie aparatu w pionie na pozycji 0 podziałki znajdującej się na górze głowicy.</p>
                <p>3.2 Naciśnij przycisk oznaczony ikoną domu aby ustawić punkt domowy (punkt początkowy)</p>
                <p>3.3 Upewnij się, że parametry 'Yaw' i 'Pitch' wynoszą 0.0. Jeśli nie powtórz kroki 3.1 i 3.2</p>
                <img src={step3_1} alt='Fuji xt20' width={'50%'}/>

            </div>
        case 3:
            return <div>
                <p>4.1 Wybierz z rozwijanej listy prawidłowy preset (Dla fuji będzie to <strong>Fuji Laow 2x8</strong>)</p>
                <img src={step4_1} alt='Fuji xt20' width={'50%'}/>
                <p>4.2 Jeśli chcesz zmienić ustawienia (przykładowo czas zatrzymania głowicy) kliknij przycisk 'Ustaw', jeśli nie to kliknij przycisk 'Strzelaj' aby rozpocząć fotografowanie.</p>

            </div>
        case 4:
            return <div>
                <p><strong>Pamiętaj o ustawieniu parametrów fotografowania i kalibracji balansu bieli</strong></p>
                <p>5.1 Aby rozpocząć fotografowanie włącz aparat i zrób zdjęcie testowe naciskając przycisk wyzwolenia migawki (po wykonaniu zdjęcia diody w aparacie nie powinny mrugać), a następnie naciśnij przycisk 'początek' na komputerze aby rozpocząć. </p>
                <img src={step5_1} alt='Fuji xt20' width={'50%'}/>
<p>Po każdym wykonanym punkcie skontroluj na aparacie czy wszystkie zdjęcia zostały wykonane i zapisane poprawnie</p>
            </div>
        default:
            return 'Unknown step';
    }
}


export const PanoheadUsage = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleGoToStep = (number) => {
        setActiveStep(number);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical" style={{backgroundColor: 'transparent'}}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel className={classes.label} onClick={() => handleGoToStep(index)}>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Wstecz
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Zakończ' : 'Dalej'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    {/*<Typography>Wszystkie kroki zakończone sukcesem</Typography>*/}
                    <Button onClick={handleReset} className={classes.button} variant="contained" color="primary">
                        Zacznij od początku
                    </Button>
                </Paper>
            )}
        </div>

    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: 10,
        marginRight: 10
    },
    resetContainer: {
        backgroundColor: 'transparent', justifyContent: 'center', display: 'flex'
    },

}));


