import React, {useState} from 'react'
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import fujiXt20 from '../assets/img/fuji_xt20.png'; // Tell webpack this JS file uses this image
import nikonD5200 from '../assets/img/nikon_D5200.png';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import WarningIcon from '@material-ui/icons/Warning';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import fuji_step_2 from '../assets/img/cameraInstallation/fuji/2.jpg'; // Tell webpack this JS file uses this image
import fuji_step_3 from '../assets/img/cameraInstallation/fuji/3.jpg'; // Tell webpack this JS file uses this image
import fuji_step_4 from '../assets/img/cameraInstallation/fuji/4.jpg'; // Tell webpack this JS file uses this image
import fuji_step_5 from '../assets/img/cameraInstallation/fuji/5.jpg'; // Tell webpack this JS file uses this image
import fuji_step_6 from '../assets/img/cameraInstallation/fuji/6.jpg'; // Tell webpack this JS file uses this image


function getSteps(camera) {
    switch(camera){
        case 'fuji_xt20':
            return ['Przygotuj niezbędne elementy' ,'Przykręć aparat do szyny', 'Zamontuj aparat na głowicy', 'Podłącz kabel'];
        case 'nikon_d5200':
            return ['krok1' ,'krok2'];


    }
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <div>
                <ol>
                    <li>Śruba 1/4" <FiberManualRecordIcon  style={{color:'red'}}/></li>
                    <li>Wkładka pozycjonująca do Fuji (czarna)<FiberManualRecordIcon style={{color:'#00E333'}}/></li>
                    <li>Szyna montażowa (mała)<FiberManualRecordIcon style={{color:'#1085F9'}}/></li>
                    <li>Kabel do Fuji (jack 2.5 mm - jack 2.5mm)<FiberManualRecordIcon style={{color:'yellow'}}/></li>
                </ol>
                <img  src={fuji_step_2} alt='Fuji xt20' width={'50%'} />

            </div>;
        case 1:
            return <div>
                <p>Przykreć aparat do szyny wykorzystując wkładkę pozycjonującą.</p>
                <p>(Obiektyw skierowany w kierunku płasko zakończonej ściany z gwintowanymi otworami.)</p>
                <img  src={fuji_step_3} alt='Fuji xt20' width={'50%'} />

            </div>;
        case 2:
            return <div>
                <p>Poluzuj śrubę na górze głowicy i wsuń szynę z zamontowanym aparatem aż do oporu wymuszonego przez wkładkę pozycjonującą.</p>
                <p>(Obiektyw skierowany w kierunku oznaczonym strzałką.)</p>
                <p> Dokręć śrubę na górze głowicy i upewnij się, że szyna jest zablokowana na właściwym miejscu</p>
                <img  src={fuji_step_4} alt='Fuji xt20' width={'50%'} />
            </div>;
        case 3:
            return <div>
                <p>Upewnij się, że głowica i aparat są wyłaczone, a następnie połącz urządzenia kablem.</p>
<p><strong>Upewnij się, że kabel jest poprowadzony z tyłu ramienia głowicy (nie ma go w kadrze)!</strong></p>
                <img  src={fuji_step_5} alt='Fuji xt20' width={'50%'} />
                <img  src={fuji_step_6} alt='Fuji xt20' width={'50%'} />
            </div>;
        default:
            return 'Unknown step';
    }
}

export const CameraInstallation=()=>{
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeCamera,setActiveCamera]=useState(null);

    const steps = getSteps(activeCamera);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleGoToStep = (number) => {
        setActiveStep(number);
    };

    return (<>
        <div className={classes.camerasWrapper}>
            <Card className={[classes.cameraCard, activeCamera==='fuji_xt20'&&classes.active]} variant="outlined" onClick={()=>setActiveCamera('fuji_xt20')}>
                <CardContent>
                    <img className={classes.img}  src={fujiXt20} alt='Fuji xt20' />

                </CardContent>
                <CardActions>
                    <Typography variant="h6">Fuji xt20</Typography>
                </CardActions>
            </Card>
            <Card className={[classes.cameraCard, activeCamera==='nikon_d5200'&&classes.active]} variant="outlined" onClick={()=>setActiveCamera('nikon_d5200')}>
                <CardContent>
                    <img className={classes.img}  src={nikonD5200} alt='Fuji xt20' />

                </CardContent>
                <CardActions>
                    <Typography variant="h6">Nikon D5200</Typography>
                </CardActions>
            </Card>
        </div>
            {activeCamera==='fuji_xt20'&&<div className={classes.contentCard}>
                <Typography variant='h4' style={{textAlign:'center', marginTop:10}}>Fuji XT20</Typography>



                <div className={classes.root}>
                    <Stepper activeStep={activeStep} orientation="vertical" style={{backgroundColor:'transparent'}}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel className={classes.label} onClick={()=>handleGoToStep(index)}>{label}</StepLabel>
                                <StepContent>
                                    <Typography>{getStepContent(index)}</Typography>
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                Wstecz
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                {activeStep === steps.length - 1 ? 'Zakończ' : 'Dalej'}
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                            {/*<Typography>Wszystkie kroki zakończone sukcesem</Typography>*/}
                            <Button onClick={handleReset} className={classes.button} variant="contained" color="primary">
                                Zacznij od początku
                            </Button>
                        </Paper>
                    )}
                </div>



            </div>}
            {activeCamera==='nikon_d5200'&&<Card className={classes.contentCard}>
                <CardContent>
                    <Typography variant='h4' style={{textAlign:'center', marginTop:10}}>Nikon D5200</Typography>
                    <Typography variant='h4' style={{textAlign:'center', marginTop:60}}><WarningIcon /></Typography>
                    <Typography variant='h6' style={{textAlign:'center'}}>Strona w przygotowaniu</Typography>

                </CardContent>
            </Card>}
        </>
    )
}

const useStyles = makeStyles({
    contentCard:{margin:'1%'},
    cameraCard: {
        width:'50%',
        display:"flex",alignItems:'center',flexDirection:'column',margin:'1%', borderBottomWidth:5,
        "&:hover, &:focus": {backgroundColor:'rgba(0, 74, 209, .1)'}
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },img:{width:'100%'},
    camerasWrapper:{
        display:'flex',
    },active:{borderBottomColor:'#004AD1', borderBottomWidth:5},

    root: {
        width: '100%',
    },
    button:{
        marginTop:10,
        marginRight:10
    },
    resetContainer:{
        backgroundColor:'transparent',  justifyContent:'center', display:'flex'
    },
});
// #2ecc71
